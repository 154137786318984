export class QuestionAnswer {
  Id: number;
  QuestionId: number;
  Text: string;
  Letter: string;
  Order: number;
  IsCorrect: boolean;
  DisplayOrder: number;
}

export class QuestionCategory {
  id: number;
  name: string;
}

export class QuestionDefaultValue {
  name: string | undefined;
  value: number | any;
  apply: boolean;
  roundId: number;
}

export class Question {
  gameId: string | undefined;
  order: number;
  questionId: number;
  questionText: string | undefined;
  imageFileName: string | undefined;
  answerType: number;
  timeLimit: number;
  answers: QuestionAnswer[];
  roundName: string;
  roundOrder: number;
  totalRounds: number;
  questionOrder: number;
  totalRoundQuestions: number;
  roundType: number;
  isLastQuestion: boolean;

  constructor() {
    this.answers = [];
  }
}
