<div class="modal-header">
  <h1 class="modal-title pull-left">Round Rules</h1>
  <button type="button" class="close pull-right" aria-label="Close" (click)="onCloseClick()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body rules-modal">
  <div class="">Welcome to Hashtag Quiz</div>
  <p>During this round we have selected <b>{{ quizType }}</b></p>

  <div *ngIf="quizType === 'Quiz'">
    <br>
    <!-- <h4>Dropdown <b>Quiz</b></h4> -->
    <p>With <b>Quiz</b>, you will get <b>{{ points }}</b> points for a correct answer</p>
    <p *ngIf="fastestFingerPoints">If you’re the fastest finger you will receive <b>{{ fastestFingerPoints }}</b> Bonus Points <span *ngIf="nextFastestFingerPoints">with the Next fastest receiving <b>{{nextFastestFingerPoints}}</b>.</span></p>
  </div>

  <div *ngIf="quizType === 'Evil'">
    <br>
    <!-- <h4>Dropdown <b>Evil</b></h4> -->
    <p>With <b>Evil</b>, you will get <b>{{ points }}</b> points for a correct answer, if you answer incorrectly you will lose <b>{{ points }}</b> Points.</p>
    <p *ngIf="fastestFingerPoints">If you’re the fastest finger you will receive <b>{{ fastestFingerPoints }}</b> Bonus Points <span *ngIf="nextFastestFingerPoints">with the Next fastest receiving <b>{{nextFastestFingerPoints}}</b>.</span></p>
  </div>


  <div *ngIf="quizType === 'Killer'">
    <br>
    <!-- <h4>Dropdown <b>Killer</b></h4> -->
    <p>With <b>Killer</b>, you will get <b>{{ points }}</b> points for a correct answer, if you answer incorrectly, you will be eliminated from the Round</p>
    <p *ngIf="fastestFingerPoints">If you’re the fastest finger you will receive <b>{{ fastestFingerPoints }}</b> Bonus Points <span *ngIf="nextFastestFingerPoints">with the Next fastest receiving <b>{{nextFastestFingerPoints}}</b>.</span></p>
  </div>


  <br>
  <br>
  <h4>We have 4 styles of answer keypad that will be delivered to your phones,</h4>

  <br>
  <p>The First being Multiple Choice, nice and easy 1 of 4 options will be correct.</p>
  <p>Next is a Sequence, which looks like a Multiple-Choice Keypad, however this time, we will ask you to put these options into the correct order.</p>
  <p>Third is a Numbers Keypad, which looks like a calculator, type in the number and click OK</p>
  <p>
    Fourth, a Letters Keypad, we are looking for the first letter of the first main word, so if the answer was The Lion King, we would be looking for the answer L,
    and this will be displayed in a qwerty style.
  </p>
  <p>
    Finally, Pictures, we will send a picture through to your phone, once you understand it, tap the screen to remove the picture, the answer keypad will arrive once I start the timer.
  </p>

  <br>
  <p>Best of luck Everyone, Lets Hashtag Quiz...</p>
</div>
<div class="modal-body rules-modal d-none">
  <div class="">Welcome to Hashtag Quiz</div>
  <p>During this round we have selected {{ '{'}}<b>Dropdown</b> / <b>Quiz</b> / <b>Killer</b> / <b>Evil</b>{{ '}' }}</p>

  <br>
  <h4>Dropdown Quiz</h4>
  <p>With Quiz, you will get X (Changeable) points for a correct answer</p>
  <p>If you’re the fastest finger you will receive X (changeable) Bonus Points with the Next fastest receiving X X X X X X X (editable etc for the options, they chose)</p>

  <br>
  <h4>Dropdown Evil</h4>
  <p>With Evil, you will get X (Changeable) points for a correct answer, if you answer incorrectly you will  X Points (copied from what they put in as changeable)</p>
  <p>If you’re the fastest finger you will receive X (changeable) Bonus Points with the Next fastest receiving X X X X X X X (editable etc for the options, they chose)</p>

  <br>
  <h4>Dropdown Killer</h4>
  <p>With Killer, you will get X (Changeable) points for a correct answer, if you answer incorrectly, you will be eliminated from the Round</p>
  <p>If you’re the fastest finger you will receive X (changeable) Bonus Points with the Next fastest receiving X X X X X X X (editable etc for the options, they chose)</p>

  <br>
  <br>
  <h4>We have 4 styles of answer keypad that will be delivered to your phones,</h4>

  <br>
  <p>The First being Multiple Choice, nice and easy 1 of 4 options will be correct.</p>
  <p>Next is a Sequence, which looks like a Multiple-Choice Keypad, however this time, we will ask you to put these options into the correct order.</p>
  <p>Third is a Numbers Keypad, which looks like a calculator, type in the number and click OK</p>
  <p>
    Fourth, a Letters Keypad, we are looking for the first letter of the first main word, so if the answer was The Lion King, we would be looking for the answer L,
    and this will be displayed in a qwerty style.
  </p>
  <p>
    Finally, Pictures, we will send a picture through to your phone, once you understand it, tap the screen to remove the picture, the answer keypad will arrive once I start the timer.
  </p>

  <br>
  <p>Best of luck Everyone, Lets Hashtag Quiz...</p>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-default" (click)="onCloseClick()">Ok</button>&nbsp;
</div>
