import { Component, EventEmitter, Injector, Input, OnInit, Output } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { AppComponentBase } from 'src/app/shared/app-component-base';

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.less']
})
export class ConfirmDialogComponent extends AppComponentBase implements OnInit {
  @Output() confirm: EventEmitter<boolean> = new EventEmitter();
  @Input() title: string;
  @Input() message: string;
  @Input() confirmButtonText = 'Confirm';
  @Input() cancelButtonText = 'Cancel';

  constructor(
    injector: Injector,
    private _modalRef: BsModalRef
  ) {
    super(injector);
  }

  ngOnInit(): void { }

  onConfirm(): void {
    this.confirm.next(true);
    this.hide();
  }

  onCloseClick(): void {
    this.confirm.next(false);
    this.hide();
  }

  public hide(): void {
    this._modalRef.hide();
    this.clearModal();
  }
}
