import { Directive, OnInit, ElementRef, Input } from '@angular/core';

@Directive({
  selector: '[appBingoBall]'
})
export class BingoBallDirective implements OnInit {
  @Input() number: number;

  private el: any;

  constructor(el: ElementRef) {
    this.el = el.nativeElement;
  }

  @Input() set setNumber(num: number) {
    this.number = num;
    console.log(num);
    if (num === 0) {
      $(this.el).css('backgroundImage', `url(../../../../assets/images/bingo-balls/ball-num.png)`);
    } else {
      console.log('image for ' + this.number);
      $(this.el).css('backgroundImage', `url(../../../../assets/images/bingo-balls/ball-num-${this.number}.png)`);
    }
  }

  ngOnInit(): void {
    $(this.el).css('backgroundImage', `url(../../../../assets/images/bingo-balls/ball-num.png)`);
  }
}
