import { Component, OnInit, Input, Injector } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { AppComponentBase } from 'src/app/shared/app-component-base';

@Component({
  selector: 'app-alert-dialog',
  templateUrl: './alert-dialog.component.html',
  styleUrls: ['./alert-dialog.component.less']
})
export class AlertDialogComponent extends AppComponentBase implements OnInit {
  @Input() title: string;
  @Input() message: any;

  constructor(
    injector: Injector,
    private _modalRef: BsModalRef
  ) {
    super(injector);
  }

  ngOnInit(): void {
  }

  onCloseClick(): void {
    this._modalRef.hide();
    this.clearModal();
  }

}
