import { EventEmitter, Injectable, Output } from '@angular/core';
import { BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { BehaviorSubject, Observable } from 'rxjs';
import * as signalR from '@microsoft/signalr';
import { environment } from 'src/environments/environment';
import { GameplayState } from '../enums/gameplay-state.enum';
import { Game, RoundSettings, UpdateTeamScoreRequest } from '../models/game.model';
import { Question } from '../models/new-question.model';
import { PlayerAnswer, LeaderBoard, LiveLeaderBoard } from '../models/player.model';
import { AuthService } from './auth.service';
import { ErrorDialogComponent } from '../components/dialogs/error-dialog/error-dialog.component';
import { GameNotification } from '../models/game-notification.model';
import { GameNotificationDialogComponent } from '../components/dialogs/game-notification-dialog/game-notification-dialog.component';
import { GameplayResponse } from '../models/gameplay-response.model';
import { PlayerStatus } from '../enums/player-status.enum';
import { Router } from '@angular/router';
import { HubConnectionStatus } from '../enums/hub-connection-status';
import { HubConnection } from '../models/hub-connection.model';

@Injectable({
  providedIn: 'root'
})
export class HostedGameplayService {
  @Output() isActionDone: EventEmitter<any> = new EventEmitter();

  public currentGameplayState: Observable<GameplayState>;
  public currentGameDetails: Observable<Game>;
  public currentQuestion: Observable<Question>;
  public currentPlayerAnswer: Observable<LiveLeaderBoard>;
  public currentLeaderBoard: Observable<LeaderBoard>;
  public currentTimer: Observable<number>;
  public currentAnswerResult: Observable<boolean>;
  public currentGameNotification: Observable<GameNotification>;
  public currentAnswersVisibility: Observable<boolean>;
  public playerStatus: Observable<PlayerStatus>;
  public connectionStatus: Observable<HubConnection>;
  public allRoundQuestions: Observable<Question[]>;
  public currentRoundSettings: Observable<RoundSettings>;

  public currentRoundNumber: number;

  game: Game;
  gamePlayHub: any;
  hasStarted = false;
  isGameOver = false;
  isNotifClose = true;
  private _isReconnecting = false;

  private currentGameplayStateSubject: BehaviorSubject<GameplayState>;
  private currentGameDetailsSubject: BehaviorSubject<Game>;
  private currentQuestionSubject: BehaviorSubject<Question>;
  private currentPlayerAnswerSubject: BehaviorSubject<LiveLeaderBoard>;
  private currentLeaderBoardSubject: BehaviorSubject<LeaderBoard>;
  private currentTimerSubject: BehaviorSubject<number>;
  private currentAnswerResultSubject: BehaviorSubject<boolean>;
  private currentGameNotificationSubject: BehaviorSubject<GameNotification>;
  private currentAnswersVisibilitySubject: BehaviorSubject<boolean>;
  private _connectionStatusSubject: BehaviorSubject<HubConnection>;
  private allRoundQuestionsSubject: BehaviorSubject<Question[]>;
  private currentRoundSettingsSubject: BehaviorSubject<RoundSettings>;

  constructor(
    private _router: Router,
    private _authService: AuthService,
    private _modalService: BsModalService,
  ) {
    [this.currentGameplayStateSubject, this.currentGameplayState] = this.initializeSubscription<GameplayState>(
      GameplayState.WaitingToStart);
    [this.currentGameDetailsSubject, this.currentGameDetails] = this.initializeSubscription<Game>(new Game());
    [this.currentQuestionSubject, this.currentQuestion] = this.initializeSubscription<Question>(new Question());
    [this.currentPlayerAnswerSubject, this.currentPlayerAnswer] = this.initializeSubscription<LiveLeaderBoard>(new LiveLeaderBoard());
    [this.currentAnswerResultSubject, this.currentAnswerResult] = this.initializeSubscription<boolean>(false);
    [this.currentLeaderBoardSubject, this.currentLeaderBoard] = this.initializeSubscription<LeaderBoard>(new LeaderBoard());
    [this.currentTimerSubject, this.currentTimer] = this.initializeSubscription<number>(0);
    [this.currentGameNotificationSubject, this.currentGameNotification] =
      this.initializeSubscription<GameNotification>(new GameNotification());
    [this.currentAnswersVisibilitySubject, this.currentAnswersVisibility] =
      this.initializeSubscription<boolean>(false);
    [this._connectionStatusSubject, this.connectionStatus] =
      this.initializeSubscription<HubConnection>(new HubConnection());
    [this.allRoundQuestionsSubject, this.allRoundQuestions] = this.initializeSubscription<Question[]>([]);
    [this.currentRoundSettingsSubject, this.currentRoundSettings] = this.initializeSubscription<RoundSettings>(new RoundSettings());
  }

  public connectToGame(gameCode: string): void {
    const self = this;
    console.log('connectToGame', gameCode);

    self.gamePlayHub = new signalR.HubConnectionBuilder()
      .withUrl(environment.gamePlayUrl + '/hubs/hostedgameplay/?gamecode=' + gameCode, {
        accessTokenFactory: () => self._authService.currentTokenValue,
      })
      // .withAutomaticReconnect([0, 2000, 10000, 30000])
      .withAutomaticReconnect([0, 2000, 10000])
      .configureLogging(signalR.LogLevel.Information)
      .build();

    // self.gamePlayHub.serverTimeoutInMilliseconds = 900000; // 15 mins

    self.subscribeToHubEvent<Game>('OnGameDetailsUpdate', (game) => {
      self.game = game;
      self.currentGameDetailsSubject.next(game);
    });

    self.subscribeToHubEvent<GameplayState>('OnGameplayState', (state: GameplayState) => {
      self.currentGameplayStateSubject.next(state);
    });

    self.subscribeToHubEvent<Question>('OnNewQuestion', (newQuestion: Question) => {
      self.currentQuestionSubject.next(newQuestion);
      self.currentAnswerResultSubject.next(null);
    });

    self.subscribeToHubEvent<LiveLeaderBoard>('OnPlayersAnswer', (answers: LiveLeaderBoard) => {
      /*
      answers = answers.sort((a, b) => a.rank - b.rank);
      console.log(answers);
      const correctAns = answers.filter(a => a.isCorrect);
      console.log(correctAns);
      const wrongAns = answers.filter(a => !a.isCorrect);
      */
      self.currentPlayerAnswerSubject.next(answers);
    });

    self.subscribeToHubEvent<number>('OnStartTimer', (timeLimit: number) => {
      self.currentTimerSubject.next(timeLimit);
      self.currentGameplayStateSubject.next(GameplayState.StartTimer);
    });

    self.subscribeToHubEvent<LeaderBoard>('OnShowLeaderBoard', (leaderboard: LeaderBoard) => {
      leaderboard.playersScore = leaderboard.playersScore.sort((a, b) => b.score - a.score);
      self.currentLeaderBoardSubject.next(leaderboard);
    });

    self.subscribeToHubEvent<boolean>('OnAnswerResult', (answerResult: boolean) => {
      self.currentAnswerResultSubject.next(answerResult);
    });

    self.subscribeToHubEvent<GameNotification>('OnGameNotification', (gameNofitication: GameNotification) => {
      self.showNotification(gameNofitication);
    });

    self.gamePlayHub.onreconnecting((error) => {
      console.log('Reconnecting...');
      self._isReconnecting = true;
      const connection: HubConnection = new HubConnection();
      connection.status = HubConnectionStatus.Reconnecting;
      self._connectionStatusSubject.next(connection);
      // self._connectionStatusSubject.next(HubConnectionStatus.Reconnecting);
    });

    self.gamePlayHub.onreconnected((connectionId) => {
      console.log('Reconnected!');
      self._isReconnecting = false;
      const connection: HubConnection = new HubConnection();
      connection.status = HubConnectionStatus.Connected;
      self._connectionStatusSubject.next(connection);
      // self._connectionStatusSubject.next(HubConnectionStatus.Connected);
    });

    self.gamePlayHub.onclose((error) => {
      console.log(error);
      if (self._isReconnecting) {
        const connection: HubConnection = new HubConnection();
        connection.status = HubConnectionStatus.Disconnected;
        connection.statusCode = '404';
        connection.endpoint = self.gamePlayHub.baseUrl;
        self._connectionStatusSubject.next(connection);
        // self._connectionStatusSubject.next(HubConnectionStatus.Disconnected);
        self._isReconnecting = false;
      }
    });

    self.gamePlayHub.start()
      .then(function () {
        if (!self.hasStarted) {
          console.warn('Connected!');
          self.currentGameplayStateSubject.next(GameplayState.WaitingToStart);
          const connection: HubConnection = new HubConnection();
          connection.status = HubConnectionStatus.Connected;
          self._connectionStatusSubject.next(connection);
          // self._connectionStatusSubject.next(HubConnectionStatus.Connected);
          self.getUpdatedGameDetails(gameCode);
        }
      })
      .catch(function (err) {
        const connection: HubConnection = new HubConnection();
        connection.status = HubConnectionStatus.ConnectionError;
        connection.statusCode = '404';
        connection.endpoint = self.gamePlayHub.baseUrl;
        self._connectionStatusSubject.next(connection);
        // self._connectionStatusSubject.next(HubConnectionStatus.ConnectionError);
        console.error(err);
      });
  }

  getUpdatedGameDetails(gameCode = this.game?.code) {
    const self = this;
    console.warn(gameCode);
    self.invokeHubEvent<Game>('GetGameDetails', gameCode, (game) => {
      self.game = game;
        self.currentGameDetailsSubject.next(game);
        self.currentGameplayStateSubject.next(game.gameplayState);

        // save a copy of quiz name and game code to localstorage
        localStorage.setItem('_actQ', game.name);
        localStorage.setItem('_actC', game.code);
    });
  }

  getRoundSettings(roundOrder: number) {
    const self = this;
    const request = {
      gameCode: self.game.code,
      roundOrder: roundOrder
    };
    console.error(request);
    self.invokeHubEvent<RoundSettings>('GetRoundSettings', request, (response) => {
      self.currentRoundSettingsSubject.next(response);
    });
  }

  getCurrentQuestion() {
    const self = this;
    const request = {
      gameCode: self.game.code,
    };

    self.invokeHubEvent<any>('GetCurrentQuestion', request, (response) => {
      self.currentRoundNumber = response.roundOrder;
      response.answers = JSON.parse(response.answers);
      response.answers = response.answers.sort((a, b) => a.DisplayOrder - b.DisplayOrder);
      self.currentQuestionSubject.next(response);
      self.currentPlayerAnswerSubject.next(new LiveLeaderBoard());
    });
  }

  getAllQuestion() {
    const self = this;
    const request = {
      gameCode: self.game.code,
    };

    self.invokeHubEvent<any>('GetAllQuestions', request, (response) => {
      const data = response.map(e => {
        e.answers = JSON.parse(e.answers);
        e.answers = e.answers.sort((a, b) => a.DisplayOrder - b.DisplayOrder);
        return e;
      });

      self.allRoundQuestionsSubject.next(data);
    });
  }

  getPlayersAnswers() {
    const self = this;
    const request = {
      gameCode: self.game.code,
    };

    self.invokeHubEvent<LiveLeaderBoard>('GetPlayersAnswers', request, (answers) => {
      /*
      answers = answers.sort((a, b) => a.rank - b.rank);

      const correctAns = answers.filter(a => a.isCorrect);
      const wrongAns = answers.filter(a => !a.isCorrect);
      */
      self.currentPlayerAnswerSubject.next(answers);
    });
  }

  disconnect(): void {
    if (this.gamePlayHub) {
      this.gamePlayHub.stop()
        .then(() => {
          console.warn('Disconnected!');
          this.resetSubscription();
          this.currentGameplayStateSubject.next(GameplayState.WaitingToStart);
        });
    }
  }

  public allowPlayer(playerId: string): void {
    const self = this;
    if (self.gamePlayHub === undefined && self.gamePlayHub.connectionState !== 'Connected') {
      return;
    }

    const request = {
      gameCode: self.game.code,
      playerId: playerId
    };

    self.invokeHubEvent('AllowPlayer', request, () => {
      self.isActionDone.next(playerId);
    });
  }

  public allowAllPlayer(): void {
    const self = this;
    if (self.gamePlayHub === undefined && self.gamePlayHub.connectionState !== 'Connected') {
      return;
    }

    const request = {
      gameCode: self.game.code,
    };

    self.invokeHubEvent('AllowAllPlayers', request, () => {
      self.isActionDone.next(true);
    });
  }

  public kickPlayer(playerId: string, callback?: (result: any) => void): void {
    const self = this;
    if (self.gamePlayHub === undefined && self.gamePlayHub.connectionState !== 'Connected') {
      return;
    }

    const request = {
      gameCode: self.game.code,
      playerId: playerId
    };

    self.invokeHubEvent('KickPlayer', request, () => {
      self.isActionDone.next(playerId);
      if (callback && typeof(callback) === 'function') {
        callback(true);
      }
    });
  }

  public start<TResult>(callback?: (result: TResult) => void): void {
    const self = this;

    if (self.gamePlayHub === undefined && self.gamePlayHub.connectionState !== 'Connected') {
        return;
    }

    const request = {
      gameCode: self.game.code
    };

    self.gamePlayHub.invoke('Start', request)
      .then((result) => {

        console.warn('@@@ hosted-gameplay-started', result);
        if (!result.success) {
          this.showRequestErrors(result);
        } else {
          this.resetSubscription();
        }
        self.isActionDone.next(result);
        if (callback && typeof(callback) === 'function') {
          callback(result);
        }
      })
      .catch((err) => {
        console.error(err.toString());
        self.isActionDone.next(true);
      });
  }

  public getNextQuestion(): void {
    const self = this;
    if (self.gamePlayHub === undefined && self.gamePlayHub.connectionState !== 'Connected') {
      return;
    }

    const request = {
      gameCode: self.game.code,
    };

    self.invokeHubEvent<any>('GetNextQuestion', request, (result: any) => {
      if (result) {
        self.reloadSubscriptionsForNextQuestion();
        result.answers = JSON.parse(result.answers);
        result.answers = result.answers.sort((a, b) => a.DisplayOrder - b.DisplayOrder);
        self.currentQuestionSubject.next(result);
        self.currentPlayerAnswerSubject.next(new LiveLeaderBoard());
        self.currentGameplayStateSubject.next(GameplayState.PrepareQuestion);
        self.isActionDone.next('GetNextQuestion');
      } else {
        self.getUpdatedGameDetails();
      }
      self.isActionDone.next(true);
    });
  }

  public showQuestionPreparation(): void {
    const self = this;
    if (self.gamePlayHub === undefined && self.gamePlayHub.connectionState !== 'Connected') {
      return;
    }

    const request = {
      gameCode: self.game.code,
    };

    self.invokeHubEvent<any>('ShowQuestionPreparation', request, (result: any) => {
      self.isActionDone.next(result);
    });
  }

  public replayQuestion(): void {
    const self = this;
    if (self.gamePlayHub === undefined && self.gamePlayHub.connectionState !== 'Connected') {
      return;
    }

    const request = {
      gameCode: self.game.code,
    };

    self.invokeHubEvent<any>('ReplayQuestion', request, (result: any) => {
      if (result) {
        result.answers = JSON.parse(result.answers);
        result.answers = result.answers.sort((a, b) => a.DisplayOrder - b.DisplayOrder);
        self.currentQuestionSubject.next(result);
        self.currentPlayerAnswerSubject.next(new LiveLeaderBoard());
        self.currentGameplayStateSubject.next(GameplayState.PrepareQuestion);
        self.isActionDone.next('ReplayQuestion');
      } else {
        self.getUpdatedGameDetails();
      }
      self.isActionDone.next(true);
    });
  }

  public getPreviousQuestion(): void {
    const self = this;
    if (self.gamePlayHub === undefined && self.gamePlayHub.connectionState !== 'Connected') {
      return;
    }

    const request = {
      gameCode: self.game.code,
    };

    self.invokeHubEvent<any>('GetPreviousQuestion', request, (result: any) => {
      if (result) {
        result.answers = JSON.parse(result.answers);
        result.answers = result.answers.sort((a, b) => a.DisplayOrder - b.DisplayOrder);
        self.currentQuestionSubject.next(result);
        self.currentPlayerAnswerSubject.next(new LiveLeaderBoard());
        self.currentGameplayStateSubject.next(GameplayState.PrepareQuestion);
        self.isActionDone.next('GetPreviousQuestion');

      } else {
        self.getUpdatedGameDetails();
      }
      self.isActionDone.next(true);
    });
  }

  public updateRoundSettings<TResult>(roundSettings: RoundSettings, callback: (result: TResult) => void): void {
    const self = this;
    if (self.gamePlayHub === undefined && self.gamePlayHub.connectionState !== 'Connected') {
      return;
    }

    roundSettings.gameCode = self.game.code,

    self.invokeHubEvent<any>('UpdateRoundSettings', roundSettings, (result: any) => {
      // self.isActionDone.next({ done: true, action: 'UpdateRoundSettings' });
      callback(result);
    });
  }

  public updateTimeLimit(newTimeLimit: number): void {
    const self = this;
    if (self.gamePlayHub === undefined && self.gamePlayHub.connectionState !== 'Connected') {
      return;
    }

    const request = {
      gameCode: self.game.code,
      timeLimit: newTimeLimit
    };

    self.invokeHubEvent<any>('UpdateTimeLimit', request, (result: any) => {
      self.isActionDone.next(result);
    });
  }

  public updateQuestionText(text: string): void {
    const self = this;
    if (self.gamePlayHub === undefined && self.gamePlayHub.connectionState !== 'Connected') {
      return;
    }

    const request = {
      gameCode: self.game.code,
      text: text
    };

    self.invokeHubEvent<any>('UpdateQuestionText', request, (result: any) => {
      self.isActionDone.next(result);
    });
  }

  public updateAnswerTexts(answerId: number, text: string, letter?: string): void {
    const self = this;
    if (self.gamePlayHub === undefined && self.gamePlayHub.connectionState !== 'Connected') {
      return;
    }

    const request: any = {
      gameCode: self.game.code,
      id: answerId,
      text: text
    };

    if (letter != null) {
      request.letter = letter;
    }

    self.invokeHubEvent<any>('UpdateAnswerTexts', request, (result: any) => {
      self.isActionDone.next(result);
    });
  }

  public updateTeamScore(updateScoreRequest: UpdateTeamScoreRequest): void {
    console.log(updateScoreRequest);
    const self = this;
    if (self.gamePlayHub === undefined && self.gamePlayHub.connectionState !== 'Connected') {
      return;
    }

    self.invokeHubEvent<any>('UpdateTeamScore', updateScoreRequest, (result: any) => {
      self.isActionDone.next(result);
    });
  }

  public releaseQuestion(): void {
    const self = this;
    if (self.gamePlayHub === undefined && self.gamePlayHub.connectionState !== 'Connected') {
      return;
    }

    const request = {
      gameCode: self.game.code,
      withAnswers: true
    };

    self.invokeHubEvent<GameplayResponse>('ReleaseQuestion', request, (result: GameplayResponse) => {
      self.currentGameplayStateSubject.next(GameplayState.StartQuestion);
      self.isActionDone.next(true);
    });
  }

  public releaseAnswerChoices(): void {
    const self = this;
    if (self.gamePlayHub === undefined && self.gamePlayHub.connectionState !== 'Connected') {
      return;
    }

    const request = {
      gameCode: self.game.code,
      withAnswers: true
    };

    self.invokeHubEvent<GameplayResponse>('ReleaseAnswers', request, (result: GameplayResponse) => {
      self.currentAnswersVisibilitySubject.next(result.success);
      self.isActionDone.next(true);
    });
  }

  public releaseAnswerResult(): void {
    const self = this;
    if (self.gamePlayHub === undefined && self.gamePlayHub.connectionState !== 'Connected') {
      return;
    }

    const request = {
      gameCode: self.game.code,
      withAnswers: true
    };

    self.invokeHubEvent<GameplayResponse>('NotifyPlayersToGetAnswerResult', request, (result: GameplayResponse) => {
      self.currentAnswerResultSubject.next(result.success);
      self.isActionDone.next(true);
    });
  }

  public startTimer(): void {
    const self = this;
    if (self.gamePlayHub === undefined && self.gamePlayHub.connectionState !== 'Connected') {
      return;
    }

    const request = {
      gameCode: self.game.code
    };

    self.invokeHubEvent('StartTimer', request, () => {
      self.isActionDone.next(true);
    });
  }

  public startTimerForHost(): void {
    const self = this;
    if (self.gamePlayHub === undefined && self.gamePlayHub.connectionState !== 'Connected') {
      return;
    }

    const request = {
      gameCode: self.game.code
    };

    self.invokeHubEvent('StartTimerForHost', request, () => {
      self.isActionDone.next(true);
    });
  }

  public startTimerForPlayer(): void {
    const self = this;
    if (self.gamePlayHub === undefined && self.gamePlayHub.connectionState !== 'Connected') {
      return;
    }

    const request = {
      gameCode: self.game.code
    };

    self.invokeHubEvent('StartTimerForPlayers', request, () => {
      console.error(request);
      self.isActionDone.next({ done: true, action: 'StartTimerForPlayer' });
    });
  }

  public endQuestion(): void {
    const self = this;
    if (self.gamePlayHub === undefined && self.gamePlayHub.connectionState !== 'Connected') {
      return;
    }

    const request = {
      gameCode: self.game.code
    };

    self.invokeHubEvent<GameplayResponse>('EndQuestion', request, (result: GameplayResponse) => {
      self.releaseAnswerResult();
      self.isActionDone.next(true);
    });
  }

  showLeaderboard(): void {
    console.log('INVOKE: showLeaderboard');
    const self = this;
    if (self.gamePlayHub === undefined && self.gamePlayHub.connectionState !== 'Connected') {
      return;
    }

    const request = {
      gameCode: self.game.code
    };

    self.invokeHubEvent('ShowLeaderBoard', request, () => {
      self.isActionDone.next('ShowLeaderBoardToPlayers');
    });
  }

  showLeaderboardToHost(callback?: (result: any) => void): void {
    console.log('INVOKE: showLeaderboardToHost');
    const self = this;
    if (self.gamePlayHub === undefined && self.gamePlayHub.connectionState !== 'Connected') {
      return;
    }

    const request = {
      gameCode: self.game.code
    };

    self.invokeHubEvent('ShowLeaderBoardToHost', request, () => {
      self.isActionDone.next('ShowLeaderBoardToHost');
      if (callback && typeof(callback) === 'function') {
        callback(true);
      }
    });
  }

  showLeaderboardToPlayers(): void {
    console.log('INVOKE: showLeaderboardToPlayers');
    const self = this;
    if (self.gamePlayHub === undefined && self.gamePlayHub.connectionState !== 'Connected') {
      return;
    }

    const request = {
      gameCode: self.game.code
    };

    self.invokeHubEvent('ShowLeaderBoardToPlayers', request, () => {
      self.isActionDone.next(true);
      self.isActionDone.next('ShowLeaderBoardToPlayers');
    });
  }

  showEndRoundPageToPlayers(): void {
    const self = this;
    if (self.gamePlayHub === undefined && self.gamePlayHub.connectionState !== 'Connected') {
      return;
    }

    const request = {
      gameCode: self.game.code
    };

    self.invokeHubEvent('ShowEndRoundPage', request, () => {
      self.isActionDone.next(true);
    });
  }

  public endGame(): void {
    const self = this;
    if (self.gamePlayHub === undefined && self.gamePlayHub.connectionState !== 'Connected') {
      return;
    }

    const request = {
      gameCode: self.game.code
    };

    self.invokeHubEvent<GameplayResponse>('EndGame', request, (result: GameplayResponse) => {
      self.releaseAnswerResult();
      self.isActionDone.next(true);
    });
  }

  public reloadSubscriptionsForNextQuestion(): void {
    console.warn('@@@@ resetSubscription()');
    // this.currentQuestionSubject.next(new Question());

    // this.currentGameNotificationSubject.next(new GameNotification());
    // this.currentAnswersVisibilitySubject.next(false);

    this.game.gameplayState = GameplayState.PrepareQuestion;
    this.currentLeaderBoardSubject.next(new LeaderBoard());
    this.currentGameDetailsSubject.next(this.game);
    this.currentTimerSubject.next(0);
    this.currentPlayerAnswerSubject.next(new LiveLeaderBoard());
    this.currentAnswerResultSubject.next(false);


  }

  public clearRoundSettings(): void {
    [this.currentRoundSettingsSubject, this.currentRoundSettings] = this.initializeSubscription<RoundSettings>(new RoundSettings());
  }

  private subscribeToHubEvent<TModel>(eventName: string, callback: (value: TModel) => void): void {
    this.gamePlayHub.on(eventName, function (outputValue?: TModel) {
      console.log('');
      console.log('');
      console.log('%c' + new Date(), 'color: #00acee');
      console.log(`%cEvent: ${eventName}`, 'color: #00acee');
      console.log(outputValue);
      console.log('');
      console.log('');
      callback(outputValue);
    });
  }

  private invokeHubEvent<TResult>(eventName: string, request: any, callback: (result: TResult) => void): void {
    this.gamePlayHub
      .invoke(eventName, request)
      .then((result) => {
        console.log('');
        console.log('');
        console.log(request);
        console.log('%c' + new Date(), 'color: #00acee');
        console.log(`%cEvent: ${eventName}`, 'color: #00acee');
        console.log(result);
        console.log('');
        console.log('');
        callback(result);
      })
      .catch((err) => {
        if (err) {
          console.error(err?.toString());
        }
      });
  }

  private initializeSubscription<TModel>(defaultValue: TModel): [BehaviorSubject<TModel>, Observable<TModel>] {
    const subject = new BehaviorSubject<TModel>(defaultValue);
    return [subject, subject.asObservable()];
  }

  private resetSubscription(): void {
    console.warn('@@@@ resetSubscription()');
    this.currentQuestionSubject.next(new Question());
    this.currentPlayerAnswerSubject.next(new LiveLeaderBoard());
    this.currentAnswerResultSubject.next(false);
    this.currentLeaderBoardSubject.next(new LeaderBoard());
    this.currentTimerSubject.next(0);
    this.currentGameNotificationSubject.next(new GameNotification());
    this.currentAnswersVisibilitySubject.next(false);
    this.currentGameDetailsSubject.next(new Game());
    this.allRoundQuestionsSubject.next([]);
  }

  private showNotification(message: GameNotification): void {
    if (!this.isNotifClose) {
      return;
    }

    const initialState: any = {};

    console.log(this._router.routerState.snapshot);

    if (message.codes && message.codes.length > 0) {
      initialState.title = 'Game Notification';
      initialState.returnUrl = this._router.url;

      const errors = message.codes.map(function(item) {
        item.description = item.code === 1005
          ? 'Your game is full please upgrade your subscription to allow more players'
          : item.description;

        return item.description;
      });
      initialState.errors =  errors;
    }

    const modalSettings: ModalOptions = {
      backdrop: 'static',
      ignoreBackdropClick: true,
      keyboard: false,
      initialState: initialState,
      class: 'modal-dialog-centered'
    };

    // this._modalService.show(GameNotificationDialogComponent, modalSettings);

    const modal: GameNotificationDialogComponent = this._modalService.show(GameNotificationDialogComponent, modalSettings).content;
    modal.close.subscribe((isClosed: boolean) => {
      this.isNotifClose = isClosed;
      console.log(isClosed);
    });
    this.isNotifClose = false;
  }


  private showRequestErrors(errJson: any): void {
    const initialState: any = {};
    if (errJson['title']) {
      initialState.title = errJson['title'];
    }
    if (errJson.messages && Array.isArray(errJson.messages)) {
      initialState.errors = errJson.messages;
    }

    const modalSettings: ModalOptions = {
      backdrop: 'static',
      ignoreBackdropClick: true,
      keyboard: false,
      initialState: initialState,
    };
    this.showErrorModal(modalSettings);
  }

  private showErrorModal(settings: ModalOptions): void {
    settings.class = 'error-modal';
    this._modalService.show(ErrorDialogComponent, settings);
    document.getElementsByClassName('error-modal')[0].parentElement.style.backgroundColor = 'rgba(0, 0, 0, 0.4)';
  }
}
