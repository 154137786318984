import { Directive, OnChanges, SimpleChanges, Input } from '@angular/core';

@Directive({
  selector: '[appQuizBuilder]'
})
export class QuizBuilderDirective implements OnChanges {
  @Input() questionId: number;
  fileUploader: any;
  isCropped = false;

  constructor() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.questionId && changes.questionId) {
      this.initialize();
    }
  }

  private initialize(): void {
    const $imageRevealSpan = $('.image-reveal');
    const $imageRevealValue = $('#image-reveal');
    console.log($imageRevealValue);
    if ($imageRevealValue.val()) {
      $imageRevealSpan.html($imageRevealValue.val().toString());
      $imageRevealValue.on('input change', () => {

        $imageRevealSpan.html($imageRevealValue.val().toString());
      });
    }
  }
}
