import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnDestroy, OnInit, Renderer2 } from '@angular/core';

@Component({
  selector: 'app-facebook-tracking',
  templateUrl: './facebook-tracking.component.html',
  styleUrls: ['./facebook-tracking.component.less']
})
export class FacebookTrackingComponent implements OnInit, OnDestroy {
  noScrip: any;
  fbScriptTag: any;

  constructor(
    @Inject(DOCUMENT) private _document,
    private renderer2: Renderer2,
  ) { }

  ngOnInit(): void {
    this.loadFacebookEventJs();
  }

  ngOnDestroy(): void {
    console.log('[event] unload FacebookEventJs:23');
    if (this.fbScriptTag) {
      this.renderer2.removeChild(this._document.head, this.fbScriptTag);
    }
    if (this.noScrip) {
      this.renderer2.removeChild(this._document.head, this.noScrip);
    }
  }

  private loadFacebookEventJs(): void {
    console.log('[event] load FacebookEventJs:33');
    // Merging into one pixel for now for tracking improvements
    this.fbScriptTag = this.renderer2.createElement('script');
    this.fbScriptTag.type = 'text/javascript';
    this.fbScriptTag.id = 'fb-pixel-free';
    this.fbScriptTag.text = `!function(f,b,e,v,n,t,s)
      {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
      n.callMethod.apply(n,arguments):n.queue.push(arguments)};
      if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
      n.queue=[];t=b.createElement(e);t.async=!0;
      t.src=v;s=b.getElementsByTagName(e)[0];
      s.parentNode.insertBefore(t,s)}(window, document,'script',
      'https://connect.facebook.net/en_US/fbevents.js');
      fbq('init', '194830662411240');
      fbq('track', 'PageView');`;

    this.renderer2.appendChild(this._document.head, this.fbScriptTag);

    this.noScrip = this.renderer2.createElement('noscript');
    this.noScrip.id = 'fb-pixel-free-noscript';
    const img = this.renderer2.createElement('img');
    img.height = '1';
    img.style = 'display:block';
    img.src = 'https://www.facebook.com/tr?id=194830662411240&ev=PageView&noscript=1';

    const documentFragment = document.createDocumentFragment();
    documentFragment.appendChild(this.noScrip);
    this.noScrip.appendChild(img);

    this.renderer2.appendChild(this._document.head, documentFragment);
  }
}
