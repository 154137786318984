import { Directive, Input } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, Validator, ValidationErrors } from '@angular/forms';

@Directive({
  selector: '[minValue][ngModel]',
  providers: [
    { provide: NG_VALIDATORS, useExisting: MinValueValidatorDirective, multi: true }
  ]
})
export class MinValueValidatorDirective implements Validator {
  @Input() minValue: any;

  constructor() { }

  validate(control: AbstractControl): ValidationErrors {
    const value = +control.value;
    if (value < this.minValue) {
      return {
        minValue: true,
      };
    }

    return null;
  }
}
