import { ModalOptions } from 'ngx-bootstrap/modal';
import { Injector } from '@angular/core';
import { PermissionsService } from './services/permissions.service';
import { LocalStorageService } from './services/local-storage.service';
import { NgForm } from '@angular/forms';
import { Howl} from 'howler';
import { SoundLoadError } from './enums/sound-load-error.enum';
import { LicenceDto, SubscriptionDto } from './service-proxies/service-proxies';

export abstract class AppComponentBase {
  defaultDtOptions: DataTables.Settings = {
    destroy: true,
    // dom: 'rt<"dt-bottom"lp<"clearfix">>',
    pageLength: 10,
    serverSide: true,
    autoWidth: false,
    language: {
      infoFiltered: '',
      zeroRecords: 'No record available',
    },
    order: [0, 'asc'],
  };

  modalSettings: ModalOptions = {
    backdrop: 'static',
    ignoreBackdropClick: true,
    keyboard: false,
  };

  permission: PermissionsService;
  localStoroage: LocalStorageService;

  isCreatingNarration = false;
  isNarrating = false;
  audio: Howl;
  isPaused = false;
  isEnded = false;
  currentSubscription: SubscriptionDto;
  license: LicenceDto;


  constructor(injector: Injector) {
    this.permission = injector.get(PermissionsService);
    this.localStoroage = injector.get(LocalStorageService);
  }

  get HasValidSubscription(): boolean {
    return (this.currentSubscription != null && !this.currentSubscription.free && this.currentSubscription.id != null)
      || (this.license != null && this.license.id && this.license.hasValidVoucher);
  }

  getFileName(path: string): string {
    const filename = path.replace(/^.*[\\\/]/, '');
    return filename.replace(/\.[^/.]+$/, '');
  }

  updateFooterPosition(): void {
    setTimeout(() => {
      if ($(document).height() > $(window).height()) {
        $('.main-content-container .copyright-container').removeClass('d-block').addClass('d-none');
      } else {
        $('.main-content-container .copyright-container').removeClass('d-none').addClass('d-block');

      }
    }, 1060);
  }

  protected validateForm(form: NgForm): boolean {
    Object.keys(form.controls).forEach(key => {
      form.controls[key].markAsTouched();
    });
    return form.valid;
  }

  protected validateEmailAddress(email: string): boolean {
    const emailRegex = new RegExp(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{1,})+$/);
    return emailRegex.test(email);
  }

  protected clearModal(): void {
    $('body').removeClass('.modal-open');
    const modalContainer = $('.modal-container');
    if (modalContainer !== null) {
      modalContainer.remove();
    }
  }

  protected getAudio(url: string): Howl {
    return new Howl({
      src: url,
      html5: true,
      autoplay: false,
      format: ['mpeg', 'mp3', 'dolby', 'webm'],
      onloaderror: (id: any, error: any) => console.error('onloaderror', 'narrate',
        { id, error, description: !isNaN(error) ? SoundLoadError[+error] : '' }),
      onplay: () => {
        this.isEnded = false;
        this.isNarrating = true;
      },
      onstop: (id: any) => {
        console.log('onStop');
        this.isNarrating = false;
        this.isPaused = false;
        this.isEnded = true;
      },
      onend: (id: any) => {
        console.log('onEnded');
        this.isNarrating = false;
        this.isPaused = false;
        this.isEnded = true;
      },
      onpause: (id: any) => {
        console.log('onpause');
        this.isNarrating = false;
        this.isPaused = true;
      },
    });
  }
}
