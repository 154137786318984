import {
  AuthServiceProxy,
  API_BASE_URL,
  QuizzesServiceProxy,
  LicencesServiceProxy,
  UsersServiceProxy,
  QuestionsServiceProxy,
  SystemsServiceProxy,
  CategoriesServiceProxy,
  TagsServiceProxy,
  PlansServiceProxy,
  MultiMediaSettingsServiceProxy,
  CountriesServiceProxy,
  CurrenciesServiceProxy,
  UserCreditsServiceProxy,
  SubscriptionsServiceProxy,
  EventsServiceProxy,
  HivesServiceProxy,
  BingosServiceProxy,
  BingoNumbersServiceProxy,
  GamesServiceProxy,
} from './service-proxies';
import { NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ErrorInterceptorService } from '../services/interceptors/error-interceptor.service';
import { AuthInterceptorService } from '../services/interceptors/auth-interceptor.service';
import { environment } from 'src/environments/environment';

@NgModule({
  providers: [
    TagsServiceProxy,
    QuestionsServiceProxy,
    QuizzesServiceProxy,
    SystemsServiceProxy,
    AuthServiceProxy,
    LicencesServiceProxy,
    UsersServiceProxy,
    CategoriesServiceProxy,
    PlansServiceProxy,
    MultiMediaSettingsServiceProxy,
    CountriesServiceProxy,
    CurrenciesServiceProxy,
    UserCreditsServiceProxy,
    SubscriptionsServiceProxy,
    EventsServiceProxy,
    HivesServiceProxy,
    BingosServiceProxy,
    BingoNumbersServiceProxy,
    GamesServiceProxy,
    { provide: API_BASE_URL, useValue: environment.apiBaseUrl },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptorService,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptorService,
      multi: true,
    },
  ],
})
export class ServiceProxyModule {}
