import { Component, Injector, Input, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { AppComponentBase } from 'src/app/shared/app-component-base';

@Component({
  selector: 'app-quiz-round-rules-dialog',
  templateUrl: './quiz-round-rules-dialog.component.html',
  styleUrls: ['./quiz-round-rules-dialog.component.less']
})
export class QuizRoundRulesDialogComponent extends AppComponentBase implements OnInit {
  @Input() quizType: string;
  @Input() fastestFinger;
  @Input() points: number;
  @Input() timeLimit;
  @Input() fastestFingerPoints: string;
  @Input() nextFastestFingerPoints: string;

  constructor(
    injector: Injector,
    private _modalRef: BsModalRef
  ) {
    super(injector);
  }

  ngOnInit(): void {
  }

  onCloseClick(): void {
    this._modalRef.hide();
    this.clearModal();
  }
}
