import { Directive, OnInit, ElementRef, Input } from '@angular/core';

@Directive({
  selector: '[appLayoutInitializer]'
})
export class LayoutInitializerDirective implements OnInit {
  @Input() bodyId: string;
  @Input() bodyClass: string;
  @Input() hideFooter: boolean;
  @Input() hideHeaderNav: boolean;
  private el: any;

  constructor(el: ElementRef) {
    this.el = el.nativeElement;
  }

  ngOnInit(): void {
    const self = this;

    $('body').attr('id', self.bodyId);
    if (self.bodyClass) {
      $('body').attr('class', this.bodyClass);
    }
    self.setMinHeight();


    setTimeout(() => {
      self.setAnswersOrderHeight();
      self.updateWindowWidth();
    }, 250);

    $(window).on('resize', () => {
      self.setMinHeight();
      self.setAnswersOrderHeight();
      self.updateWindowWidth();
      self.alignChoicesHeight();
    });

    setTimeout(() => {
      $('body#login .logo-container, .menu-container .logo-container').removeClass('bounce-top').addClass('wobble-hor-top');
    }, 1000);

    window.setInterval(() => {
      if ($('body#login .logo-container, .menu-container .logo-container').hasClass('wobble-hor-top')) {
        $('body#login .logo-container, .menu-container .logo-container').removeClass('wobble-hor-top');
      } else {
        $('body#login .logo-container, .menu-container .logo-container').addClass('wobble-hor-top');
      }
    }, 4000);

    $('.menu-toggle-container .menu-toggle-btn, .mobile-menu-toggler .menu-toggle-btn').off('click');
    $('.menu-toggle-container .menu-toggle-btn, .mobile-menu-toggler .menu-toggle-btn').on('click', (e) => {
      e.preventDefault();
      const windowWidth = $(window).width();
      setTimeout(() => {
        self.setAnswersOrderHeight();
      }, 500);

      if ($('body').hasClass('menu-container-shown')) {
        $('body').removeClass('menu-container-shown').addClass('menu-container-hidden');
        $('.game-connection-status').removeClass('menu-show');

      } else if ($('body').hasClass('menu-container-hidden')) {
        $('body').removeClass('menu-container-hidden').addClass('menu-container-shown');
        $('.game-connection-status').addClass('menu-show');
      } else {
        if (windowWidth > 991) {
          $('body').addClass('menu-container-hidden');
        } else {
          $('body').addClass('menu-container-shown');
        }
      }
    });

    const menuToggler = $('.user-menu-content a.user-menu-toggler');
    menuToggler.off('click');
    menuToggler.on('click', (e) => {
      e.preventDefault();

      if (menuToggler.parent().hasClass('user-menu-shown')) {
        menuToggler.parent().removeClass('user-menu-shown').addClass('user-menu-hidden');
      } else if (menuToggler.parent().hasClass('user-menu-hidden')) {

        menuToggler.parent().removeClass('user-menu-hidden').addClass('user-menu-shown');
      } else {
        menuToggler.parent().addClass('user-menu-shown');
      }
    });

    if (this.hideFooter) {
      $('.copyright-container').addClass('d-none');
    } else {
      $('.copyright-container').removeClass('d-none');
    }

    if (this.hideHeaderNav) {
      $('.main-content-header').addClass('d-none');
    } else {
      $('.main-content-header').removeClass('d-none');
    }



   $(window).on('scroll', () => {
    if ($(window).scrollTop() + $(window).height() === $(document).height()) {
      $('.main-content-container .copyright-container').removeClass('d-none').addClass('d-block');
    } else {
      $('.main-content-container .copyright-container').removeClass('d-block').addClass('d-none');
    }
   });

  }

  public alignChoicesHeight(): void {
    // console.warn('alignAnswerChoicesHeight');

    const answersElements = $('body#standard-quiz-gamer-proper .main-content-container .choice-entry .choice-content .choice');
    if (answersElements.length > 0) {
      const heights = [];
      answersElements.each((i, el) => {
        heights[i] = $(el).find('.choice-label').height();
      });

      const maxHeight = Math.max.apply(null, heights);
      // console.warn('maxHeight: ', maxHeight);
      $('body#standard-quiz-gamer-proper .main-content-container .choice-entry .choice-content .choice').css({'height' : maxHeight });
    }

    this.setAnswersOrderHeight();
  }

  public setAnswersOrderHeight(): void {
    // console.log('setAnswersOrderHeight()');
    const quizContainerHeight = $('.quiz-box-content .quiz-container').outerHeight() + 48;
    const answerHeaderHeight = $('.answers-section-header h3').outerHeight() + 55;
    const answerTallyHeight = $('.answer-tally').length ? $('.answer-tally').outerHeight() : 0;
    const quizImgContainer = $('.standard-quiz-answer-col .question-img').length;
    const audioBtns = $('audio-play-button-container').outerHeight();

    let quizImgContainerHeight = 0;
    let answerOrderHeight = 0;

    if (quizImgContainer !== 0) {
        quizImgContainerHeight = $('.standard-quiz-answer-col .question-img').outerHeight() + 0;
        answerOrderHeight = quizContainerHeight - answerHeaderHeight - quizImgContainerHeight - answerTallyHeight - (audioBtns ?? 0);
    } else {
        answerOrderHeight = quizContainerHeight - answerHeaderHeight - quizImgContainerHeight - answerTallyHeight;
    }
    $('.answer-order-container .answer-order-content').height(answerOrderHeight);

    this.checkAnswersResultWidth();
  }

  private checkAnswersResultWidth(): void {
    const answerResultWidth = $('.answers-section-header').width();

    if (answerResultWidth <= 271) {
      $('.answers-section-header .winner-buzzer-button').addClass('position-relative btn-block');
      $('.answers-section-header .winner-buzzer-button').css({'padding' : '4px 8px'});
    } else {
      $('.answers-section-header .winner-buzzer-button').removeClass('position-relative btn-block');
      $('.answers-section-header .winner-buzzer-button').css({'padding' : '4px 10px'});
    }
  }



  private setMinHeight(): void {
    const bodyMinHeight = $(window).height();
    const copyrightHeight = $('.copyright-container').outerHeight();
    const mainContentHeight = bodyMinHeight - copyrightHeight;
    $('body, .menu-container').css({
      'min-height': bodyMinHeight
    });


    $('.main-content-container').css({
      'min-height': mainContentHeight
    });
    // console.log('Setting Minimum Height to: ' + bodyMinHeight, mainContentHeight);

  }

  private updateWindowWidth(): void {
    const windowWidth = $(window).width();
    if (windowWidth < 1081 && windowWidth > 767) {
      $('.quizzes-management-table-container .table').removeClass('text-nowrap');

    } else {
      $('.quizzes-management-table-container .table').addClass('text-nowrap');

    }
  }
}
