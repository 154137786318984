import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {
  tempDataIndex = [
    'modifiedRounds',
    'modifiedEntryFee',
    'modifiedLifelines',
    'modifiedBuzzersState',
    'currentQuestion',
    'currentQuestion',
    'modifiedHandsetDelayState',
    '_winnersBuzzers',
    'modifiedSharingState',
    'hideAnswerResult',
    '_actQ',
    '_actC'
  ]

  constructor() { }

  public setItem(key: string, value: string): void {
    localStorage.setItem(key, value);
  }

  public setObjectItem<TObject>(key: string, value: TObject): void {
    const objString = JSON.stringify(value);
    this.setItem(key, objString);
  }

  public getItem(key: string): string {
    return localStorage.getItem(key);
  }

  public getObjectItem<TObject>(key: string): TObject {
    const objString = localStorage.getItem(key);
    const obj: TObject = JSON.parse(objString);
    return obj;
  }

  public removeItem(key: string): void {
    localStorage.removeItem(key);
  }

  public removeTempData(): void {
    for (const index of this.tempDataIndex) {
      this.removeItem(index);
    }
  }

  public clearAll(): void {
    localStorage.clear();
  }
}
