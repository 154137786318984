<div class="modal-header">
  <h5 class="modal-title">Kick Team</h5>
  <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="onCloseClick()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body" >
  <p class="mb-0">Are you sure you want to kick {{ player.teamName }} from the game?</p>

</div>
<div class="modal-footer">
  <button type="button" class="btn btn-style-1 join-approve" (click)="onSaveClick()">
    <span>Confirm
      <i class="fa fa-spinner fa-pulse mr-1"  *ngIf="saving"></i>
    </span>
  </button>
  <button type="button" class="btn btn-style-3 join-approve" (click)="onCloseClick()">Cancel</button>
</div>
