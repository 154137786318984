import { Component, OnInit, Input, Injector } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { AppComponentBase } from 'src/app/shared/app-component-base';

@Component({
  selector: 'app-error-dialog',
  templateUrl: './error-dialog.component.html',
  styleUrls: ['./error-dialog.component.less']
})
export class ErrorDialogComponent extends AppComponentBase implements OnInit {
  @Input() title: string;
  @Input() errors: any;
  @Input() isUnregistered: boolean = null;
  @Input() showLoginLink: boolean;
  @Input() showOkButton = true;
  isErrorArray = true;

  errorKeys: string[] = [];
  customErrorMessage: string;
  unregisteredHost: boolean;


  constructor(
    injector: Injector,
    private _modalRef: BsModalRef
  ) {
    super(injector);
   }

  ngOnInit(): void {
    if (!this.title) {
      this.title = 'Request Failed';
    }
    if (this.errors) {
      console.log(this.errors);
      if (this.errors.ConfirmPassword && this.errors.ConfirmPassword.length === 1 &&
        this.errors.ConfirmPassword[0] === 'Should be the same with the password.') {
        this.customErrorMessage = 'Passwords should match';
        this.isErrorArray = false;
      } else if (!Array.isArray(this.errors)) {
        this.isErrorArray = false;
        Object.keys(this.errors).sort().forEach(key => this.errorKeys.push(key));
      }
    }

    if (this.isUnregistered) {
      this.unregisteredHost = this.isUnregistered;
    }

  }

  onCloseClick(): void {
    this._modalRef.hide();
    this.clearModal();
  }
}
