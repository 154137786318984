import { Directive, Input } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, Validator, ValidationErrors } from '@angular/forms';

@Directive({
  selector: '[maxValue][ngModel]',
  providers: [
    { provide: NG_VALIDATORS, useExisting: MaxValueValidatorDirective, multi: true }
  ]
})
export class MaxValueValidatorDirective implements Validator {
  @Input() maxValue: any;

  constructor() { }

  validate(control: AbstractControl): ValidationErrors {
    const value = +control.value;
    if (value > this.maxValue) {
      return {
        maxValue: true,
      };
    }

    return null;
  }
}
