import { Component, EventEmitter, Injector, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AppComponentBase } from 'src/app/shared/app-component-base';
import { UpdateTeamScoreRequest } from 'src/app/shared/models/game.model';
import { PlayerScore } from 'src/app/shared/models/player.model';
import { AuthService } from 'src/app/shared/services/auth.service';
import { HostedGameplayService } from 'src/app/shared/services/hosted-gameplay.service';

@Component({
  selector: 'app-kick-player-dialog',
  templateUrl: './kick-player-dialog.component.html',
  styleUrls: ['./kick-player-dialog.component.less']
})
export class KickPlayerDialogComponent extends AppComponentBase implements OnInit, OnDestroy {
  @Output() onConfirm: EventEmitter<boolean> = new EventEmitter();
  @Input() player: PlayerScore = new PlayerScore();
  @Input() gameCode: string;
  saving = false;

  private destroy$ = new Subject();

  constructor(
    injector: Injector,
    private _modalRef: BsModalRef,
    private _authService: AuthService,
    private _gamePlayService: HostedGameplayService
  ) {
    super(injector);
  }

  ngOnInit(): void {
    this._gamePlayService.isActionDone
    .pipe(takeUntil(this.destroy$))
    .subscribe(done => {
      console.log(done);
      if (done) {
        this.close(true);
      }
      this.saving = false;
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
  }

  onSaveClick(): void {
    this.saving = true;


    this._gamePlayService.kickPlayer(this.player.userId, (success) => {
      if (success) {
        this.close(true);
      }
    });
  }

  onCloseClick(): void {
    this.clearModal();
    this.close();
  }

  private close(save = false): void {
    this.clearModal();
    this.onConfirm.emit(save);
    this._modalRef.hide();
  }

}
