<div class="modal-header">
  <h1 class="modal-title pull-left">{{ title }}</h1>
  <button type="button" class="close pull-right" aria-label="Close" (click)="onCloseClick()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body" *ngIf="message">
  <p>{{ message }}</p>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-default" (click)="onCloseClick()">Ok</button>&nbsp;
</div>
