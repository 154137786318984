import { Player } from './player.model';
import { GameplayState } from '../enums/gameplay-state.enum';
import { QuizEntryFeeChargeSource, QuizEntryFeeChargeType } from '../service-proxies/service-proxies';
import { QuizMode } from '../enums/user-action.enum';

export class Game {
  id: string | undefined;
  quizId: number;
  name: string | undefined;
  description: string | undefined;
  startDate: moment.Moment;
  endedDate: moment.Moment;
  categoryName: string | undefined;
  freeToPlay: boolean;
  stake: number;
  houseMoney: number;
  lifeLine: number;
  quizTypeId: number | undefined;
  continuous: boolean;
  buzzerSources: string | undefined;
  absoluteMaxPlayers: number;
  minPlayers: number;
  maxPlayers: number;
  players: Player[] | undefined;
  gameplayState: GameplayState;
  quizMode: QuizMode;
  totalRounds: number;
  totalQuestions: number;
  prize: string | undefined;
  handhelDelay: number;

  code: string | undefined;
  allowLifelines: boolean;
  fiftyFiftyLifelineLimit: number;
  askAudienceLifelineLimit: number;
  cheatLifelineLimit: number;
  chargeEntryFee: boolean;
  chargeSource: QuizEntryFeeChargeSource;
  entryFee: number;
  chargeType: QuizEntryFeeChargeType;

  constructor() {
    this.players = [];
  }
}

export class UpdateTeamScoreRequest {
  userId: string;
  playerId: string;
  score: number;
  teamName: string;
  gameCode: string;
}
export class RoundSettings {
  quizId: number;
  roundId: number;
  roundOrder: number;
  defaultPoints: number;
  timeLimit: number | undefined;
  bonusPoints: string[] | undefined;
  type: number;
  gameCode: string | undefined;
}

