export class ImportQuizRequest {


  constructor(
    public quizName: string,
    public importFile: File
  ) {
  }

}
