import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import * as _ from 'lodash';
import { AuthService } from '../auth.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthInterceptorService implements HttpInterceptor {
  excludedDomains = [
    environment.currencyConverterApi.domainUrl,
    environment.geoLocation.dbIp.domainUrl
  ];

  constructor(
    private _authService: AuthService,
  ) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const currentToken = this._authService.currentTokenValue;

    // req.url.indexOf(this.excludedDomain) === -1)
    if (!_.isNil(currentToken) && !this.isDomainExcluded(req.url)) {
      req = req.clone({
        setHeaders: {
          Authorization: `Bearer ${currentToken}`
        }
      });
    }
    return next.handle(req);
  }


  isDomainExcluded(url: string): boolean {
    for (const domain of this.excludedDomains) {
      if (url.indexOf(domain) !== -1) {
        return true;
      }
    }

    return false;
  }
}
