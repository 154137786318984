import { Component, EventEmitter, Injector, Input, OnInit, Output } from "@angular/core";
import { BsModalRef } from "ngx-bootstrap/modal";
import { AppComponentBase } from "src/app/shared/app-component-base";
import { QuizzesServiceProxy } from "src/app/shared/service-proxies/service-proxies";
import { environment } from "src/environments/environment";
import { ImportQuizRequest } from "./import-quiz-request";

@Component({
  selector: 'delete-account-dialog',
  templateUrl: './import-quiz-dialog.component.html',
  styleUrls: ['./import-quiz-dialog.component.less']
})
export class ImportQuizDialogComponent extends AppComponentBase implements OnInit {
  @Output() result: EventEmitter<boolean> = new EventEmitter();

  message = 'Are you sure you want to delete your Account?';
  isProcessing = false;
  isErrorResult = false;
  title = 'Import Quiz';
  importFileConfiguration = environment.fileUpload.importQuiz;

  model: ImportQuizRequest = new ImportQuizRequest('', null);
  submitted = false;

  constructor(
    injector: Injector,
    private _modalRef: BsModalRef,
    private _quizzesService: QuizzesServiceProxy
  ) {
    super(injector);
  }

  ngOnInit(): void { }

  onSubmit() { this.submitted = true; }


  onImport(): void {
    this.importQuiz();
  }

  onCloseClick(): void {
    this.result.next(false);
    this._modalRef.hide();
    this.clearModal();
  }

  onImportFileChange(files: FileList): void {
    if (!files || files.length == 0)
      return;

    const file = files[0];

  }

  private importQuiz() {
    this.title = '';
    this.message = "Importing quiz...";
    this.isProcessing = true;

    // this._quizzesService.import()
    //   .subscribe(response => {
    //     if (response.success){
    //       this._modalRef.hide();
    //       this.result.next(true);
    //     } else {
    //       this.isErrorResult = true;
    //       // list the error message
    //       //this.message = 'There is an error importing data.'
    //     }
    //   });
  }

  // private validateFile(file: File): Promise<boolean> {
  //   return new Promise((resolve) => {
  //     const invalidUploadMessageTitle = 'Invalid File';

  //     if (!this.validateFileExtension(file)) {
  //       this.showError(invalidUploadMessageTitle, ['Invalid buzzer file extension. Only mp3 files are allowed.']);
  //       resolve(false);
  //     }
  //     console.log('File size: ' + file.size + 'bytes');
  //     if (!this.validateFileSize(file.size, this.buzzerFileConfiguration.maxFileSizeBytes)) {
  //       this.showError(invalidUploadMessageTitle, ['Invalid buzzer file size. The mp3 file must be less than 250Kb.']);
  //       resolve(false);
  //     } else {
  //       this.validateAudioDuration(file).then((validationResult) => {
  //         if (!validationResult) {
  //           this.showError(invalidUploadMessageTitle, ['Invalid buzzer duration. The mp3 play time should not exceed 10 seconds.']);
  //         }
  //         resolve(validationResult);
  //       });
  //     }


  //   });
  // }

  // private validateFileExtension(file: File): boolean {
  //   const fileExtension = this.getFileExtension(file.name).toLocaleLowerCase();
  //   const index = this.buzzerFileConfiguration.allowedExtensions.indexOf(`.${fileExtension}`);
  //   return index >= 0;
  // }

  // private validateFileSize(size: number, maxLimit: number): boolean {
  //   return size <= maxLimit;
  // }
}
