import { PlayerStatus } from '../enums/player-status.enum';
import { QuestionAnswer } from './new-question.model';

export class Player {
  gameId: string | undefined;
  userId: string | undefined;
  username: string | undefined;
  teamName: string | undefined;
  playersCount: number;
  quizName: string | undefined;
  quizDescription: string | undefined;
  startDate: moment.Moment;
  score: number;
  credits: number;
  fiftyFiftyCredits: number;
  extraLifeCredits: number;
  askAudienceCredits: number;
  cheatCredits; number;
  fiftyFiftyCreditsUsed: number;
  extraLifeCreditsUsed: number;
  askAudienceCreditsUsed: number;
  cheatCreditsUsed: number;
  status: PlayerStatus;
  buzzer: string | undefined;
  winning: number;
  joinedDate: moment.Moment;
  isLoading: boolean;
  action: string | undefined;

  isExecutingAction: boolean;
}

export class LiveLeaderBoard {
  totalPlayers: number;
  totalPlayersAnswered: number;
  totalCorrectAnswers: number;
  playersAnswer: PlayerAnswer[];
}

export class PlayerAnswer {
  username: string | undefined;
  teamName: string | undefined;
  isCorrect: boolean;
  noAnswer: boolean;
  displayOrder: number;
  rank: number;
  buzzer: string | undefined;
  elapseTime: number;

  answers: QuestionAnswer[];
  bonusPoints: number;
  points: number;
}

export class LeaderBoard {
  playersScore: PlayerScore[];

  constructor() {
    this.playersScore = [];
  }
}

export class PlayerScore {
  score: number;
  status: number;
  teamName: string | undefined;
  username: string | undefined;
  userId: string | undefined;
  rank: number;
}

export class PaidPlayer {
  userId: string;
  amount: number;
}
