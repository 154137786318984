export enum GameplayState {
  WaitingToStart = 0,
  Starting = 1,
  Started = 2,
  StartingFirstRound = 3,
  StartRound = 4,
  EndRound = 5,
  PrepareQuestion = 6,
  StartQuestion = 7,
  EndQuestion = 8,
  Ended = 9,
  StartTimer = 10
}
