import { Component, OnInit, Input, Injector, EventEmitter, Output } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import * as moment from 'moment';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { AppComponentBase } from 'src/app/shared/app-component-base';
import { Game } from 'src/app/shared/models/game.model';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-submit-error-logs-dialog',
  templateUrl: './submit-error-logs-dialog.component.html',
  styleUrls: ['./submit-error-logs-dialog.component.less']
})
export class SubmitErrorLogsDialogComponent extends AppComponentBase implements OnInit {
  @Output() confirm: EventEmitter<boolean> = new EventEmitter();
  @Input() confirmButtonText = 'Ok';
  @Input() cancelButtonText = 'Cancel';
  @Input() title: string;
  @Input() message: any;
  @Input() quiz: Game;
  @Input() endpoint: string;
  @Input() errorCode: string;
  @Input() showSubmitLogsBtn = false;
  @Input() showCloseBtn = false;
  @Input() emailRecipient = environment.support.submitError.mailTo;
  @Input() emailSubject = environment.support.submitError.subject;

  isErrorArray = true;

  errorKeys: string[] = [];
  customErrorMessage: string;
  unregisteredHost: boolean;
  mailTo: SafeUrl;

  constructor(
    injector: Injector,
    private _modalRef: BsModalRef,
    private _sanitizer: DomSanitizer,
  ) {
    super(injector);
    this.showSubmitLogsBtn = this.localStoroage.getObjectItem<boolean>('canSubmitLog');
  }

  ngOnInit(): void {
    this.createMailTo();
  }

  async createMailTo() {
    const space = '%20';
    const column = '%3A';
    const newLine = '%0D%0A';

    const quizName = this.localStoroage.getItem('_actQ');
    const quizCode = this.localStoroage.getItem('_actC');

    const timeStamp = moment().utc().format('DD/MM/YYYY HH:mm:ss UTC');
    const ip = this.localStoroage.getItem('cip');

    console.warn(this.showSubmitLogsBtn, this.endpoint, encodeURIComponent(this.endpoint));
    this.mailTo = this._sanitizer.bypassSecurityTrustUrl(
      'mailto:' + this.emailRecipient + '?' +
      'subject=' + this.emailSubject + '&' +
      'body=' +
        'Timestamp' + column + space + timeStamp + newLine +
        'Quiz' + space + 'Name' + column + space + quizName + newLine +
        'Quiz' + space + 'Number' + column + space + quizCode + newLine +
        'IP' + space + 'Address' + column + space + ip + newLine +
        'Endpoint'  + column + space + encodeURIComponent(this.endpoint) + newLine +
        'Error' + space + 'Code' + column + space + this.errorCode + newLine);
  }

  onConfirm(): void {
    this.confirm.next(true);
    this.onCloseClick();
  }

  onCloseClick(): void {
    this._modalRef.hide();
    this.clearModal();
  }
}
