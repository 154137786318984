import { Component, EventEmitter, Injector, Input, OnInit, Output } from "@angular/core";
import { BsModalRef } from "ngx-bootstrap/modal";
import { AppComponentBase } from "src/app/shared/app-component-base";
import { LicencesServiceProxy } from "src/app/shared/service-proxies/service-proxies";

@Component({
  selector: 'delete-account-dialog',
  templateUrl: './delete-account-dialog.component.html',
  styleUrls: ['./delete-account-dialog.component.less']
})
export class DeleteAccountDialogComponent extends AppComponentBase implements OnInit {
  @Output() result: EventEmitter<boolean> = new EventEmitter();

  message = 'Are you sure you want to delete your Account?';
  isProcessing = false;
  isErrorResult = false;
  title = 'Delete Account';

  constructor(
    injector: Injector,
    private _modalRef: BsModalRef,
    private _licencesService: LicencesServiceProxy
  ) {
    super(injector);
  }

  ngOnInit(): void { }

  onConfirm(): void {
    this.deleteAccount();
  }

  deleteAccount() {
    this.title = '';
    this.message = 'Deleting Account...';
    this.isProcessing = true;

    this._licencesService.delete()
      .subscribe(response => {
        if (response.success) {
          this._modalRef.hide();
          this.result.next(true);
          this.clearModal();
        } else {
          this.isErrorResult = true;
          this.message = 'There is an error deleting your Account.'
        }
      });
  }

  onCloseClick(): void {
    this.result.next(false);
    this._modalRef.hide();
    this.clearModal();
  }
}
