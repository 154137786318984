<div class="modal-header">
  <h1 class="modal-title pull-left">No valid subscription</h1>
  <button type="button" class="close pull-right" aria-label="Close" (click)="onCloseClick()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <p>Free downloadable content is only available to paid subscribers, click <a href="/main/subscriptions">here</a> to upgrade.</p>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-default" (click)="onCloseClick()">Ok</button>&nbsp;
</div>
