import { Component, OnInit, Input, Injector } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { AppComponentBase } from 'src/app/shared/app-component-base';

@Component({
  selector: 'app-no-valid-subscription-dialog',
  templateUrl: './no-valid-subscription-dialog.component.html',
  styleUrls: ['./no-valid-subscription-dialog.component.less']
})
export class NoValidSubscriptionDialogComponent extends AppComponentBase implements OnInit {

  constructor(
    injector: Injector,
    private _modalRef: BsModalRef
  ) {
    super(injector);
  }

  ngOnInit(): void {
  }

  onCloseClick(): void {
    this._modalRef.hide();
    this.clearModal();
  }

}
