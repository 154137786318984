<div class="modal-header">
  <h5 class="modal-title">{{ title }}</h5>
  <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="onCloseClick()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <!-- <p class="text-center">
    <i class="fas fa-exclamation-triangle" style="
      font-size: 72px;
      color: #facea8;
    "></i>
  </p> -->
  <ul *ngIf="isErrorArray">
    <li *ngFor="let error of errors">{{ error }}</li>
  </ul>
</div>
<div class="modal-footer">
  <button *ngIf="!isLicencee" type="button" class="btn btn-style-1 join-approve" (click)="onCloseClick()">Retry</button>
  <button *ngIf="isLicencee" type="button" class="btn btn-style-1 join-approve" (click)="onUpgrade()">Upgrade</button>
  <button type="button" class="btn btn-style-3 join-approve" (click)="onCloseClick()">Ignore</button>
</div>
