// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  apiBaseUrl: 'https://localhost:44396',
  clientId: 'manager.ui.quiz',
  gamePlayUrl: 'https://localhost:44330',
  gamePlayUIUrl: 'http://localhost:6350',
  playersLoginPageUrl: 'http://localhost:6350/account/login',
  hqFbPageUrl: 'https://wwww.facebook.com/hashtagquiz',
  stripePublicKey: 'pk_test_51HzK7hKphsKAIUIVZXk7nl87bLUpCv4ckJ4BDts8Z6AK7nclJr2fXzoe0ASsdaxrLXrlP0ynWxth6IAEvOcYAA3500eng0jmGc',
  fbRootDomain: 'https://www.facebook.com/',
  fileUpload: {
    buzzer: {
      allowedExtensions: ['.mp3'],
      maxFileSizeBytes: 249890,
    },
    bingoNumber: {
      allowedExtensions: ['.mp3'],
      maxFileSizeBytes: 5289384, // 5MB
    },
    importQuiz: {
      allowedExtensions: ['.csv'],
      maxFileSizeBytes: 5289384, // 5MB
    }
  },
  links: {
    listYourEvents: 'http://hashtagquiz.co.uk/community-events',
    toturials: 'http://youtube.com/c/hqfmhqfm',
    faq: 'https://hashtagquiz.co.uk/host-faq'
  },
  currencyConverterApi: {
    domainUrl: 'https://free.currconv.com',
    apiVersion: '/api/v7',
    apiKey: 'f5e96175d5249204cd36',
    defaultCurrency: 'GBP'
  },
  files: {
    audio: {
      applause: 'https://hq-assets.s3.eu-west-2.amazonaws.com/sounds/others/applause.mp3'
    }
  },
  geoLocation: {
    dbIp: {
      domainUrl: 'https://api.db-ip.com/v2/free',
      endpoint: '/self',
    }
  },
  support: {
    submitError: {
      mailTo: 'support@hashtagquiz.co.uk',
      subject: 'In game error report'
    }
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
