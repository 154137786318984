import { Injectable, Injector } from '@angular/core';
import { AuthService } from './auth.service';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class PermissionsService {
  roles: string[] = [];
  rolePermissionAssignments: any = {
    'Admin': [
      'Dashboard',
      'Categories',
      'Quizzes',
      'Quizzes.Launch',
      'Questions',
      'Questions.Count',
      'SoundBites',
      'Playlists',
      'Users',
      'Licencees',
      'System',
      'Financials',
      'Transactions',
      'Events',
      'Hive',
      'Reporting'
    ],
    'LicenceeManager': [
      'Dashboard',
      'Account',
      'Account.Subscription',
      'Account.Users',
      'Account.BuyCredit',
      'Subscriptions',
      'Quizzes',
      'Quizzes.Purchase',
      'Quizzes.Launch',
      'Questions',
      'Questions.Count',
      'SoundBites',
      'Playlists',
      'Users',
      'Events',
      'Hive'
    ],
    'QuizManager': [
      'Dashboard',
      'Quizzes',
      'Quizzes.Launch',
      'Questions',
      'Questions.Count',
      'SoundBites',
      'Playlists',
      'Users',
      'Licencees',
      'Financials',
      'Events',
      'Hive'
    ],
    'QuizMaster': [
      'Dashboard',
      'Quizzes',
      'Quizzes.Launch',
      'Questions',
      'Questions.Count',
      'SoundBites',
      'Playlists',
      'Events',
      'Hive'
    ],
  };

  constructor(
    private _authService: AuthService,
  ) {
    this._authService.currentToken
      .subscribe(token => {
        if (token) {
          this.roles = this.extractRolesFromToken(token);
        }
      });
  }

  public isGranted(permissionToCheck: string): boolean {
    let hasPermission = false;
    _.forEach(this.roles, role => {
      const rolePermissions: string[] = this.rolePermissionAssignments[role];
      if (rolePermissions && rolePermissions.includes(permissionToCheck)) {
        hasPermission = true;
        if (hasPermission) {
          return;
        }
      }
    });
    return hasPermission;
  }

  private extractRolesFromToken(token: string): string[] {
    const tokenObjStr = atob(token.split('.')[1]);
    const tokenObj = JSON.parse(tokenObjStr);
    let roles: string[] = [];
    if (tokenObj) {
      if (_.isArray(tokenObj.role)) {
        roles = tokenObj.role;
      } else {
        roles.push(tokenObj.role);
      }
    }
    return roles;
  }
}
