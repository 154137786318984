
<a href="javascript:;" (click)="close()" class="delete-btn"><i class="fas fa-times"></i></a>
<div class="question-img cursor-pointer" >
  <div class="question-img-container">
    <div class="question-img-content">
      <h3 class="question-img-header">{{ text }}</h3>
      <div class="img-container">
        <img [src]="src">
      </div>
    </div>
  </div>
</div>
