
<div class="modal-header">
  <h5 class="modal-title">Late Joiner</h5>
  <!-- <button type="button" class="close" (click)="onCloseClick()" aria-label="Close">
    <span aria-hidden="true">&times;</span>
  </button> -->
</div>
<div class="modal-body">
  <p><span class="team-name"><strong>{{ player.teamName }}</strong></span> would like to join. Allow joining?</p>
</div>
<div class="modal-footer">
  <button
    type="button"
    (click)="onAllowPlayer(player.userId);"
    class="btn btn-style-2 join-approve"
    [disabled]="isLoading"
  >
    <i *ngIf="!isAllowingPlayer" class="fas fa-check"></i>
    <span *ngIf="isAllowingPlayer"><i class="fa fa-spinner fa-pulse"></i></span>
  </button>
  <button
    type="button"
    class="btn btn-style-3 join-reject"
    (click)="onKickPlayer(player.userId)"
    [disabled]="isLoading"
  >
    <i *ngIf="!isKickingPlayer" class="fas fa-times"></i>
    <span *ngIf="isKickingPlayer"><i class="fa fa-spinner fa-pulse"></i></span>
  </button>
</div>
