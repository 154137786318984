import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalModule } from 'ngx-bootstrap/modal';
import { ServiceProxyModule } from './service-proxies/service-proxy.module';
import { ToastrModule } from 'ngx-toastr';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { AlertDialogComponent } from './components/dialogs/alert-dialog/alert-dialog.component';
import { ErrorDialogComponent } from './components/dialogs/error-dialog/error-dialog.component';
import { LayoutInitializerDirective } from './directives/layout-initializer.directive';
import { DatatableDirective } from './directives/datatable.directive';
import { QuizBuilderDirective } from './directives/quiz-builder.directive';
import { MaxValueValidatorDirective } from './directives/validators/max-value-validator.directive';
import { MinValueValidatorDirective } from './directives/validators/min-value-validator.directive';
import { LocalCurrencyPipe } from './pipes/local-currency.pipe';
import { GameNotificationDialogComponent } from './components/dialogs/game-notification-dialog/game-notification-dialog.component';
import { PlayerJoinDialogComponent } from './components/dialogs/player-join-dialog/player-join-dialog.component';
import { ConfirmDialogComponent } from './components/dialogs/confirm-dialog/confirm-dialog.component';
import { NoValidSubscriptionDialogComponent } from './components/dialogs/no-valid-subscription-dialog/no-valid-subscription-dialog.component';
import { UpdatePlanDialogComponent } from './components/dialogs/update-plan-dialog/update-plan-dialog.component';
import { DeleteAccountDialogComponent } from './components/dialogs/delete-account-dialog/delete-account-dialog.component';
import { ImportQuizDialogComponent } from './components/dialogs/import-quiz-dialog/import-quiz-dialog.component';
import { FacebookTrackingComponent } from './components/facebook-tracking/facebook-tracking.component';
import { ResendPasswordResetDialogComponent } from './components/dialogs/resend-password-reset-dialog/resend-password-reset-dialog.component';
import { FullImageDialogComponent } from './components/dialogs/full-image-dialog/full-image-dialog.component';
import { QuizRoundRulesDialogComponent } from './components/dialogs/quiz-round-rules-dialog/quiz-round-rules-dialog.component';
import { SubmitErrorLogsDialogComponent } from './components/dialogs/submit-error-logs-dialog/submit-error-logs-dialog.component';
import { BingoBallDirective } from './directives/bingo-ball.directive';
import { KickPlayerDialogComponent } from './components/dialogs/kick-player-dialog/kick-player-dialog.component';

@NgModule({
  declarations: [
    LayoutInitializerDirective,
    BingoBallDirective,
    DatatableDirective,
    AlertDialogComponent,
    ErrorDialogComponent,
    SubmitErrorLogsDialogComponent,
    QuizBuilderDirective,
    MaxValueValidatorDirective,
    MinValueValidatorDirective,
    LocalCurrencyPipe,
    GameNotificationDialogComponent,
    PlayerJoinDialogComponent,
    ConfirmDialogComponent,
    NoValidSubscriptionDialogComponent,
    UpdatePlanDialogComponent,
    DeleteAccountDialogComponent,
    ImportQuizDialogComponent,
    FacebookTrackingComponent,
    ResendPasswordResetDialogComponent,
    FullImageDialogComponent,
    QuizRoundRulesDialogComponent,
    KickPlayerDialogComponent,
  ],
  imports: [
    CommonModule,
    ServiceProxyModule,
    ModalModule.forRoot(),
    ToastrModule.forRoot({
      preventDuplicates: true,
      resetTimeoutOnDuplicate: true
    }),
    TabsModule.forRoot(),
    TooltipModule.forRoot(),
  ],
  exports: [
    ServiceProxyModule,
    TooltipModule,
    TabsModule,
    LayoutInitializerDirective,
    BingoBallDirective,
    DatatableDirective,
    QuizBuilderDirective,
    MaxValueValidatorDirective,
    MinValueValidatorDirective,
    LocalCurrencyPipe,
    FacebookTrackingComponent,
  ],
  providers: [
    LocalCurrencyPipe
  ],

})
export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
      return {
          ngModule: SharedModule,
      };
  }
}
