<div class="modal-header">
  <h1 class="modal-title pull-left">{{ title }}</h1>
  <button type="button" class="close pull-right" aria-label="Close" (click)="onCloseClick()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body" *ngIf="message">
  <p>{{ message }}</p>
  <p *ngIf="showSubmitLogsBtn">
    Please Click
    <a [href]="mailTo" class="link">Here</a>
    to Email this error to Support
  </p>
  <p>To Continue Please refresh you browser</p>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-style-3" *ngIf="showCloseBtn" (click)="onCloseClick()">
    {{ cancelButtonText }}
  </button>
  <button type="button" (click)="onConfirm()" class="btn btn-style-2 join-approve">
    {{ confirmButtonText }}
  </button>

</div>
