import { Component, Injector, Input, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { AppComponentBase } from 'src/app/shared/app-component-base';

@Component({
  selector: 'app-full-image-dialog',
  templateUrl: './full-image-dialog.component.html',
  styleUrls: ['./full-image-dialog.component.less']
})
export class FullImageDialogComponent extends AppComponentBase implements OnInit {
  @Input() src: string;
  @Input() text: string;

  constructor(
    injector: Injector,
    private _modalRef: BsModalRef
  ) {
    super(injector);
  }

  ngOnInit(): void {
  }

  close(): void {
    this._modalRef.hide();
    this.clearModal();
  }
}
