
<div class="modal-header">
  <h5 class="modal-title">{{ title }}</h5>
  <button type="button" class="close pull-right" aria-label="Close" (click)="onCloseClick()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body" *ngIf="message">
  <p>{{ message }}</p>
</div>
<div class="modal-footer">
  <button type="button" (click)="onConfirm()" class="btn btn-style-2 join-approve">
    {{ confirmButtonText }}
  </button>
  <button type="button" class="btn btn-style-3" (click)="onCloseClick()">
    {{ cancelButtonText }}
  </button>
</div>
