import { Component, EventEmitter, Injector, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { AppComponentBase } from 'src/app/shared/app-component-base';
import { HostedGameplayService } from 'src/app/shared/services/hosted-gameplay.service';

@Component({
  selector: 'app-game-notification-dialog',
  templateUrl: './game-notification-dialog.component.html',
  styleUrls: ['./game-notification-dialog.component.less']
})
export class GameNotificationDialogComponent extends AppComponentBase implements OnInit {
  @Output() close: EventEmitter<boolean> = new EventEmitter();
  @Input() title: string;
  @Input() errors: any;
  @Input() returnUrl: string;

  isErrorArray = true;
  isLicencee: boolean;

  constructor(
    injector: Injector,
    private _router: Router,
    private _modalRef: BsModalRef,
  ) {
    super(injector);
    console.log(this.permission.roles, this.isLicencee);

    this.isLicencee = this.permission.roles.some(r => r === 'LicenceeManager');
  }

  ngOnInit(): void {
    console.log(this.returnUrl);
  }

  onUpgrade(): void {
    this._router.navigate(['/main/subscriptions'], { queryParams: { returnUrl: this.returnUrl } } );
    this.close.next(true);
    this._modalRef.hide();
    this.clearModal();
  }

  onCloseClick(): void {
    this.close.next(true);
    this._modalRef.hide();
    this.clearModal();
  }
}
