import { Injectable, Inject, Optional } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { LocalStorageService } from './local-storage.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  public currentToken: Observable<string>;
  public lsCurrentTokenKey = 'currentToken';
  public lsLicenceeId = 'licenceeId';
  public userName = 'userName';
  public userId = 'userId';

  private currentTokenSubject: BehaviorSubject<string>;

  constructor(
    private _httpClient: HttpClient,
    private _localStorageService: LocalStorageService,
    private _router: Router,
  ) {
    this.currentTokenSubject = new BehaviorSubject<string>(this._localStorageService.getItem(this.lsCurrentTokenKey));
    this.currentToken = this.currentTokenSubject.asObservable();
  }

  public get currentTokenValue(): string {
    return this.currentTokenSubject.value;
  }

  public get currentUserId(): string {
    const tokenObjStr = atob(this.currentTokenValue.split('.')[1]);
    const tokenObj = JSON.parse(tokenObjStr);

    return tokenObj.sub;
  }

  login(username: string, password: string): Promise<any> {
    const request = {
      Client_Id: environment.clientId,
      Response_Type: 'token',
      Username: username,
      Password: password,
    };
    return this._httpClient.post<any>(environment.apiBaseUrl + '/auth/authorize', request)
      .toPromise()
      .then(result => {
        this._localStorageService.setItem(this.lsCurrentTokenKey, result.access_token);
        this.currentTokenSubject.next(result.access_token);

        const tokenObjStr = atob(result.access_token.split('.')[1]);
        const tokenObj = JSON.parse(tokenObjStr);
        console.log(tokenObj);
        this._localStorageService.setItem(this.lsLicenceeId, tokenObj.licenceid);
        this._localStorageService.setItem(this.userName, tokenObj.unique_name);
        this._localStorageService.setItem(this.userId, tokenObj.sub);
      });
  }

  logout(): void {
    // this._localStorageService.removeItem(this.lsCurrentTokenKey);
    this._localStorageService.clearAll();
    this.currentTokenSubject.next(null);
    this._router.navigate(['/account/login']);
  }

  private decodedToken(token: string): any {
    const tokenObjStr = atob(token.split('.')[1]);
    return JSON.parse(tokenObjStr);
  }
}
