import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { throwError, Observable, empty, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { BsModalService, BsModalRef, ModalOptions } from 'ngx-bootstrap/modal';
import { ErrorDialogComponent } from '../../components/dialogs/error-dialog/error-dialog.component';
import { ConfirmDialogComponent } from '../../components/dialogs/confirm-dialog/confirm-dialog.component';
import { ForgotPasswordModel, LicencesServiceProxy, UsersServiceProxy } from '../../service-proxies/service-proxies';
import { AlertDialogComponent } from '../../components/dialogs/alert-dialog/alert-dialog.component';
import * as _ from 'lodash';
import { ResendPasswordResetDialogComponent } from '../../components/dialogs/resend-password-reset-dialog/resend-password-reset-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class ErrorInterceptorService implements HttpInterceptor {
  constructor(
    private _router: Router,
    private _modalService: BsModalService,
    private _usersServiceProxy: UsersServiceProxy,
    private _licencesService: LicencesServiceProxy,
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(catchError((err) => {
      console.log('@@ Error intercepted: ', err);
      if (err.status === 401) {
        if (err.error && err.error.title) {
          let errTitle;
          let email;
          let isLoginError = false;
          let isUnableToLoginError = false;
          let isUnregistered = false;
          let errors: boolean = null;

          if (err.error.title === 'Unauthorized') {
            errTitle = 'Email/Password combination did not match.';
          } else if (err.error.type === 'EmailNotRegistered') {
            errTitle = 'Email is not registered as a host.';
            isUnregistered = true;
            errors = true;
          } else if (err.error.type === 'UnableToLogin') {
            errTitle = 'Unable to login, do you want to reset your password?';
            email = err.error.detail;
            isLoginError = true;
            isUnableToLoginError = true;
            this.showResetPasswordModal(errTitle, email);
            return throwError({
              errType: err.error.type,
              title: errTitle
            });
          } else if (err.error.type === 'InvalidAccountRole') {
            errTitle = err.error.title;
            return throwError({
              errType: err.error.type,
              title: errTitle
            });
          } else {
            errTitle = err.error.title;
          }

          const modalSettings: ModalOptions = {
            backdrop: 'static',
            ignoreBackdropClick: true,
            keyboard: false,
            initialState: {
              title: errTitle,
              errors: errors,
              isUnregistered: isUnregistered
            },
          };

          this.showErrorModal(modalSettings);
          return throwError(errTitle);
        } else {
          console.log(document.querySelectorAll('.modal.in'), this._modalService.getModalsCount(), this._modalService);

          if (this._modalService.getModalsCount() >= 1) {
            for (let i = 1; i <= this._modalService.getModalsCount(); i++) {
              this._modalService.hide(i);
            }
          }
        }

        this._router.navigate(['account/login']);
      } else if (err.status === 400) {
        console.log(err.status);
        if (err.error instanceof Blob) {
          err.error.text().then(errText => {
            const errJson = JSON.parse(errText);

            if (err.url.indexOf('/Users/ResetPassword') !== -1 && errJson.errors.InvalidToken) {
              console.log(errJson.errors.InvalidToken);
              this.showResendPasswordResetDialog('Invalid Token');
            } else if (err.url.indexOf('/Users/ResetPassword') !== -1 && errJson.errors.TokenExpired) {
              console.log(errJson.errors.TokenExpired);
              this.showResendPasswordResetDialog('The token was expired');
            } else {
              this.showRequestErrors(errJson);
            }
          });
        } else {
          if (err.url.indexOf('/auth/authorize') !== -1) {
            console.log(err.error.errors);
            if (_.has(err.error.errors, 'Username')) {
              const message =  err.error.errors.Username.map(e => e = e.replace('Username', 'Email'));
              delete err.error.errors.Username;
              err.error.errors.Email = message;
            }
          }
          this.showRequestErrors(err.error);
        }
        return throwError('Bad Request');
      }

      const error = err.message || err?.error?.message || err?.statusText || err || 'Unknow error';
        return throwError(error);
    }));
  }

  private showRequestErrors(errJson: any): void {
    const initialState: any = {};
    if (errJson['title']) {
      initialState.title = errJson['title'];
    }
    if (errJson['errors']) {
      initialState.errors = errJson['errors'];
    }
    const modalSettings: ModalOptions = {
      backdrop: 'static',
      ignoreBackdropClick: true,
      keyboard: false,
      initialState: initialState,
    };

    this.showErrorModal(modalSettings);
  }

  private showErrorModal(settings: ModalOptions): void {
    settings.class = 'error-modal';
    this._modalService.show(ErrorDialogComponent, settings);
    document.getElementsByClassName('error-modal')[0].parentElement.style.backgroundColor = 'rgba(0, 0, 0, 0.4)';
  }

  private showResendPasswordResetDialog(title: string): void {
    const modalSettings: ModalOptions = {
      backdrop: 'static',
      ignoreBackdropClick: true,
      keyboard: false,
      initialState: {
        title: title
      }
    };
    this._modalService.show(ResendPasswordResetDialogComponent, modalSettings);
  }

  private showResetPasswordModal(title: string, email: string): void {
    const modalSettings: ModalOptions = {
      backdrop: 'static',
      ignoreBackdropClick: true,
      keyboard: false,
      initialState: {
        title: title,
        email: email,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No'
      },
    };
    const unableToLoginDialog: ConfirmDialogComponent = this._modalService.show(ConfirmDialogComponent, modalSettings).content;

    unableToLoginDialog.confirm.subscribe((isResetPassword: boolean) => {
      if (isResetPassword) {
        const forgotPasswordModel = new ForgotPasswordModel();
        forgotPasswordModel.email = email;
        this._usersServiceProxy.forgotPassword(forgotPasswordModel)
        .subscribe(() => {
          modalSettings.initialState = {
            title: 'Reset Password Request Sent',
            message: 'A reset password link was sent to your email address. Please check your spam/junk folder.',
          };

          modalSettings.initialState = {
            title: 'Reset Password Request Sent',
            message: 'A reset password link was sent to your email address. Please check your spam/junk folder.',
          };
          unableToLoginDialog.hide();
          this._modalService.show(AlertDialogComponent, modalSettings);
        });
        this._router.navigate(['/account/login']);
      } else {
        unableToLoginDialog.hide();
      }
    });
  }
}
