<div class="modal-header">
  <h1 class="modal-title pull-left">{{ title }}</h1>
  <button type="button" class="close pull-right" aria-label="Close" (click)="onCloseClick()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body" *ngIf="errors">
  <ul *ngIf="isErrorArray">
    <li *ngFor="let error of errors">{{ error }}</li>
  </ul>
  <ul *ngIf="!isErrorArray">
    <li *ngFor="let errorKey of errorKeys">
      <span *ngIf="errorKey === 'ConfirmPassword'">Confirm Password</span>
      <span *ngIf="errorKey !== 'ConfirmPassword'">{{ errorKey }}</span>
      <ul *ngIf="errors[errorKey].length > 0">
        <li *ngFor="let errorMsg of errors[errorKey]">{{ errorMsg }}</li>
      </ul>
    </li>
  </ul>
  <ul *ngIf="customErrorMessage">
    <li>{{customErrorMessage }}</li>
  </ul>
  <span *ngIf="unregisteredHost">
    Click <a href="/account/register">here</a> to register as a host
  </span>
  <span *ngIf="showLoginLink">
    Click <a href="/account/login">here</a> to Login
  </span>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-default" (click)="onCloseClick()" *ngIf="showOkButton">Ok</button>&nbsp;
</div>
