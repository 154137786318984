<div class="modal-header">
  <h1 class="modal-title pull-left">{{ title }}</h1>
</div>
<div class="modal-body">
  <p>{{ message }}</p>
</div>
<div class="modal-footer" *ngIf="!isProcessing">
  <span>
    <button type="button" (click)="onConfirm()" class="btn btn-style-2" >
      Confirm
    </button>
    &nbsp;
    <button type="button" class="btn btn-style-3" (click)="onCloseClick()">Cancel</button>&nbsp;
  </span>
</div>
<div class="modal-footer" *ngIf="isErrorResult">
  <button type="button" class="btn btn-default" (click)="onCloseClick()">Ok</button>&nbsp;
</div>
