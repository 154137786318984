import { Component, EventEmitter, Injector, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AppComponentBase } from 'src/app/shared/app-component-base';
import { Player } from 'src/app/shared/models/player.model';
import { HostedGameplayService } from 'src/app/shared/services/hosted-gameplay.service';

@Component({
  selector: 'app-player-join-dialog',
  templateUrl: './player-join-dialog.component.html',
  styleUrls: ['./player-join-dialog.component.less']
})
export class PlayerJoinDialogComponent extends AppComponentBase implements OnInit, OnDestroy {
  @Output() modalAccept: EventEmitter<string> = new EventEmitter();
  @Input() player: Player;
  @Input() level: number;

  isAllowingPlayer = false;
  isKickingPlayer = false;
  isLoading = false;

  private destroy$ = new Subject();
  constructor(
    injector: Injector,
    private _gameplayService: HostedGameplayService,
    private _modalService: BsModalService
  ) {
    super(injector);
  }

  ngOnInit(): void {
    this._gameplayService.currentGameDetails
      .pipe(takeUntil(this.destroy$)).subscribe(updatedGameDetails => {
        const player = updatedGameDetails.players != null && updatedGameDetails.players.length > 0 ? updatedGameDetails.players : [];

        const currentPlayerModal = player.find(p => p.userId === this.player.userId);
        if (currentPlayerModal) {
          this.isLoading = false;
          this.isAllowingPlayer = false;
          this.isKickingPlayer = false;
        }
      });

    this._gameplayService.isActionDone.pipe(takeUntil(this.destroy$)).subscribe(playerId => {
      if (playerId === this.player.userId) {
        this.modalAccept.emit(playerId);
        this._modalService.hide(this.level);
        this.clearModal();
      }
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
  }

  onKickPlayer(playerId: string): void {
    this.isLoading = true;
    this.isKickingPlayer = true;
    this._gameplayService.kickPlayer(playerId);
  }

  onAllowPlayer(playerId: string): void {
    this.isLoading = true;
    this.isAllowingPlayer = true;
    this._gameplayService.allowPlayer(playerId);
  }
}
